<template>
  <v-container>
    <v-card>
      <v-card-title class="headline d-flex font-weight-thin" :class="titleColor"> Сотрудники </v-card-title>
      <v-card-text class="pa-0">
        <v-text-field
          v-if="!tableLoading"
          v-model="searchEmps"
          append-icon="mdi-magnify"
          class="mb-5 px-5"
          label="Поиск.."
          single-line
          hide-details
        />
        <v-progress-linear v-if="tableLoading && isMobile" indeterminate :color="loadingColor" />
        <v-list v-if="!tableLoading && isMobile" three-line subheader>
          <template v-for="(item, index) in EmployeesList">
            <v-list-item :key="item.ID">
              <v-list-item-content class="pb-1">
                <v-list-item-subtitle v-text="item.CreDate" />
                <v-list-item-subtitle v-text="item.ID" />
                <v-list-item-title v-text="item.fio" />
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="index + 1 < EmployeesList.length" :key="index" />
          </template>
        </v-list>
        <v-data-table
          v-else-if="!isMobile"
          :headers="headers"
          :items="EmployeesList"
          :items-per-page="10"
          :page="page"
          class="elevation-0"
          no-data-text="нет данных"
          :search="searchEmps"
          :loading="tableLoading"
          loading-text="Загрузка данных.."
          disable-sort
          @page-count="pageCount = $event"
          hide-default-footer
        >
          <template v-slot:item.action="{}">
            <v-btn icon>
              <v-icon> mdi-square-edit-outline </v-icon>
            </v-btn>
          </template>
          <template v-slot:item.FlgStatus="{ item }">
            <div v-if="item.flgstatus == 0" style="color: #23903a">Трудоустроен</div>
            <div v-else-if="item.flgstatus == 1" style="color: #676767">Не активен</div>
            <div v-else style="color: #b62a38">
              <span v-if="item.firereasondesc"> Уволен по причине: {{ item.firereasondesc }} </span>
              <span v-else> Уволен </span>
            </div>
          </template>
        </v-data-table>
        <div v-if="pageCount" class="text-center my-3 py-3" :class="isMobile ? 'leftMinus' : ''">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7" circle />
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'employees',
    data() {
      return {
        pageCount: 0,
        searchEmps: null,
        page: 1,
        tableLoading: true,
        toolbarcolor: process.env.VUE_APP_THEME_COLOR,
        amount: 10,
        headers: [
          {
            text: 'ЛН',
            align: 'start',
            sortable: true,
            value: 'empid',
          },
          { text: 'ФИО', value: 'fio' },
          { text: 'Дата создания', value: 'credate' },
          { text: 'Статус', value: 'flgstatus' },
          { text: 'Отработано смен', value: 'cbcount' },
          { text: 'Последняя смена', value: 'cbdatemax' },
          // { text: 'Детали', value: 'action' },
        ],
      }
    },
    mounted() {
      this.$store.dispatch('loadEmployeesList').then(() => {
        this.tableLoading = false
      })
    },
    computed: {
      ...mapGetters({
        checks: 'checks',
        selectMsg: 'rotDetail',
        titleColor: 'titleColor',
        loadingColor: 'loadingColor',
        EmployeesList: 'EmployeesList',
      }),
      isMobile() {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            return true
          case 'sm':
            return true
          case 'md':
            return false
          case 'lg':
            return false
          case 'xl':
            return false
          default:
            return false
        }
      },
    },
  }
</script>
